import styled from "styled-components"
import {SectionPageTitle} from "../Section"


export const HeroBanner = styled.div`
background-color:${props => props.bgColors};   
padding-top: ${props=>props.pt};
padding-bottom: ${props=>props.pb};	
position: relative;	
    &:after{
        content:'';
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:80%;
        background: -moz-linear-gradient(top,  rgba(0,39,101,0.8) 90%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(top,  rgba(0,39,101,0.8) 90%,rgba(255,255,255,0) 100%);
        background: linear-gradient(to bottom,  rgba(0,39,101,0.8) 90%,rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002765', endColorstr='#00ffffff',GradientType=0 );
    } 
    &:before{
        content:attr(data-shadowtext);
        display:${props => (props.bgBefore ? 'block' : 'none')};
        opacity:0.1;
        text-transform:uppercase;
        position:absolute;
        bottom: 20%;
        left: 0;
        color:#fff;
        font-weight: 700;
        background-color:#002765;
        width: ${props=>props.beforeWidth};
        
        font-size:30px;
        line-height:40px;
        @media(min-width:768px){
          font-size:80px;
          line-height:60px;
        }
        @media(min-width:992px){
          font-size:120px;
          line-height:90px;
          
        }
        @media(min-width:1200px){
          font-size:160px;
          line-height:120px;    
        }
        @media(min-width:1440px){
          font-size:200px;
          line-height:150px;    
        }
        @media(min-width:1600px){
          font-size:240px;
          line-height:180px;
        }
        @media(max-width:991.98px){
          text-align: ${props=>props.beforeAlign};
        }
    }
`

HeroBanner.defaultProps = {
    bgColors: "transparent",
    beforeAlign: "left",
    beforeWidth: "auto",
}


export const HeroTitle = styled(SectionPageTitle)`
  position:relative;
  color:#fff;
  font-weight:900;
  text-align:center;
  margin-bottom:30px;
  @media (min-width:992px){
    margin-bottom:140px;
    text-align:left;
    margin-left:0;
    &:before{
      content:'';
      position:absolute;
      top:0;
      left:0;
      width:36px;
      height:2px;
      background-color:#C20000;
    }
  }
  @media (max-width:575.98px) {
    font-size: 30px;
    line-height: 40px;
  }
`
export const HeroCaption = styled.div`	
  position: relative;
  z-index: 2;
  width: 100%;
  padding:100px 0 0;
  @media (min-width:1200px){
    padding:160px 0 0;
  }
`
export const HeroContent = styled.div`	
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  margin:0 -15px;
`
export const HeroLeft = styled.div`	
  position:relative;
  width:100%;
  padding:0 15px;
  @media (min-width:992px){
    flex:0 0 58.333333%;
    max-width:58.333333%;
  }
  @media (min-width:1200px){
    flex: 0 0 50%;
    max-width: 50%;
  }

`
export const HeroRight = styled.div`	
  position:relative;
  width:100%;
  padding:0 15px;
  @media (min-width:992px){
    flex:0 0 41.666667%;
    max-width:41.666667%;
  }

`
export const HeroCard = styled.div`	
  background-color:#fff;
  border-radius:15px;
  margin:0 auto;
  box-shadow:0px 20px 20px rgba(0, 0, 0, 0.20);
  width:100%;
  max-width:698px;
  padding:30px 20px 40px;
  @media(min-width:576px){
  padding:30px 30px 40px;
  }
  @media(min-width:992px){
    box-shadow:25px 25px 45px rgba(0,0,0,0.35);   
    margin-left:auto;
  }
  @media(min-width:1200px){
    padding:40px 30px 50px 30px;
  }
  @media(min-width:1600px){
    padding:40px 38px 50px;
  }
`